import React, { Component } from 'react';
import { navigate, graphql, StaticQuery } from 'gatsby';
import { ThemeProvider } from '../context/ThemeProvider';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import loadable from '@loadable/component';

import MediaBanner from '../components/Sections/MediaBanner';
import MultiColumn from '../components/Sections/MultiColumn';
import Carousel from '../components/Sections/Carousel';
import Form from '../components/Sections/Form';
import ListingMultiColumn from '../components/Sections/ListingMultiColumn';
// import ListingMap from '../components/Sections/ListingMap';
import Content from '../components/Sections/Content';
// import Accordion from '../components/Sections/Accordion';
// import MediaCarousel from '../components/Sections/MediaCarousel';
import { isVideo } from '../utilities/helpers';
import schemaGenerator from '../utilities/SchemaGenerator';

// import logoMark from '../images/logomark-black-01.svg';
// import logo2 from '../images/dinesen collection wo background square.png';

export class Page extends Component {

    componentDidMount() {
        let element = document.querySelector('main > div');
        element.classList.add('primary-element-settings');

        window.addEventListener('resize', this.carouselDescriptionAdjustment);
        this.carouselDescriptionAdjustment();
        
        const blurredImageDiv = document.querySelectorAll(".blurred-img:not(.loaded)");
        
        blurredImageDiv.forEach((item) => {
            const img = item.querySelector("img");
            if (!img) return;
            function loaded() {
                item.classList.add("loaded")
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.carouselDescriptionAdjustment);
    }

    carouselDescriptionAdjustment= () => {
        // Set min-height of other carousel's description based on the maximum height of the carousel's description
        let carousels = document.querySelectorAll('.carousel-container');

        for (let c = 0; c < carousels.length; c++) {
            const descriptions = carousels[c].querySelectorAll('.carousel-item--content__description p');
            let height = 0;

            for (let d = 0; d < descriptions.length; d++) {
                if (descriptions[d].clientHeight > height) {
                    let carouselDescriptions = carousels[c].querySelectorAll('.carousel-item--content__description');
                    for (let index = 0; index < carouselDescriptions.length; index++) {
                        carouselDescriptions[index].style.minHeight = descriptions[d].clientHeight + "px";
                    }
                    height = descriptions[d].clientHeight;
                }
            }
        }
    }

    buildPageSections(sections, path) {
        let pageSections = [];

        if (sections.length <= 0) {
            return pageSections;
        }

        const { mediaCollection } = this.props;

        for (let i = 0; i < sections.length; i++) {
            if (sections[i].type === "MediaBanner") {
                let isMediaVideo = isVideo(sections[i].section.media.url);
                let mediaQuery = null;

                if (!isMediaVideo) {
                    let imageVariant = sections[i].section.media.variant === "Full width" ? "" : "";
                    let path = sections[i].section.media.url.split('/');
                    let imgName = `${imageVariant ? imageVariant + '_' : ''}${path[path.length - 1]}`;
                    mediaQuery = mediaCollection.find(x => x.fluid.originalName === imgName);
                }

                // const MediaBanner = loadable(() => import('../components/Sections/MediaBanner'));
                pageSections.push(<MediaBanner mediaQuery={mediaQuery} {...sections[i].section} />);
            }
            else if (sections[i].type === "MultiColumn") {
                let medias = sections[i].section.columns.map(x => {
                    let path = x.media.url.split('/');
                    // return "Original_" + path[path.length - 1];
                    return path[path.length - 1];
                });

                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));
                pageSections.push(<MultiColumn mediaQueries={mediaQueries} {...sections[i].section} />);
            }
            else if (sections[i].type === "Carousel") {
                let medias = sections[i].section.slides.map(x => {
                    let path = x.media.url.split('/');
                    return path[path.length - 1];
                });

                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));
                // const Carousel = loadable(() => import('../components/Sections/Carousel'));
                pageSections.push(<Carousel mediaQueries={mediaQueries} {...sections[i].section} />);
            }
            else if (sections[i].type === "Form") {
                pageSections.push(<Form {...sections[i].section} />);
            }
            else if (sections[i].type === "ListingMultiColumn") {
                let medias = sections[i].section.listings.map(x => {
                    let path = x.pictures[0].url.split('/');
                    return path[path.length - 1];
                });

                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));
                // const ListingMultiColumn = loadable(() => import('../components/Sections/ListingMultiColumn'));
                pageSections.push(<ListingMultiColumn mediaQueries={mediaQueries} {...sections[i].section} />);
            }
            else if (sections[i].type === "Content") {
                let customStyle = {};

                if (path === '/') {
                    customStyle.topPadding = `0px`;
                    customStyle.bottomPadding = `60px`;
                }

                pageSections.push(<Content {...sections[i].section}  {...customStyle}/>);
            }
            else if (sections[i].type === "Map") {
                const ListingMap = loadable(() => import('../components/Sections/ListingMap'));
                pageSections.push(<ListingMap {...sections[i].section} />);
            }
            else if (sections[i].type === "Accordion") {
                const Accordion = loadable(() => import('../components/Sections/Accordion'));
                pageSections.push(<Accordion {...sections[i].section} />);
            }
            else if (sections[i].type === "MediaCarousel") {
                let medias = sections[i].section.slides.map(x => {
                    let path = x.imageItem.url.split('/');
                    return path[path.length - 1];
                });
                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));

                const MediaCarousel = loadable(() => import('../components/Sections/MediaCarousel'));
                pageSections.push(<MediaCarousel {...sections[i].section} mediaQueries={mediaQueries} />);
            }
        }

        return pageSections;
    }

    onSearchClick = ({ checkin, checkout, guests }) => {
        navigate('/listings/', {
            state: {
                checkin,
                checkout,
                guests
            }
        });
    }

    render() {
        const { layout, theme, sections, seo, url } = this.props.pageContext;
        const pageSections = this.buildPageSections(sections, url);
        const searchBarSettings = {
            pageType: 'Page',
            onSearchClick: this.onSearchClick
        }
        let ldScript = "";
        const { mediaCollection } = this.props;

        seo.customLdScript = [];

        if (url.indexOf('faq') > 0) {
            let data = sections.filter(x => x.type === "Accordion");
            if (data.length > 0) {
                data.forEach(d => {
                    ldScript += schemaGenerator.generateFAQSchema(d.section.accordionItems);
                });

                seo.customLdScript.push(ldScript);
            }
        }

        let homPageLogoQuery = null;
        if (layout.homePageLogo) {
            let homePageLogoImagePath = layout.homePageLogo.url.split('/');
            homPageLogoQuery = mediaCollection.find(x => homePageLogoImagePath[homePageLogoImagePath.length - 1] === x.fluid.originalName);
        }

        let panoramicImagePath = layout.footer.panoramicImage.url.split('/');
        let panoramicMediaQuery = mediaCollection.find(x => panoramicImagePath[panoramicImagePath.length - 1] === x.fluid.originalName);        

        if (layout.footer) {
            layout.footer.panoramicImageQuery = panoramicMediaQuery;
            layout.footer.footerImageQuery = homPageLogoQuery;
        }

        if (layout.header) {
            layout.header.headerImageQuery = homPageLogoQuery;
        }

        return (
            <ThemeProvider theme={theme}>
                <Layout header={layout.header} footer={layout.footer} favicon={layout.favicon} searchBar={searchBarSettings} seo={seo} 
                    defaultHeaderTextToBlack={true} showHeaderMark={false} path={url}>
                    {
                        url === "/" && 
                        <React.Fragment>
                            <div className="page-logo">
                                <Img fluid={homPageLogoQuery.fluid} alt="Dinesen Collection Luxury Condos" />
                            </div>
                        </React.Fragment>
                    }
                    {pageSections}
                </Layout>
            </ThemeProvider>
        )
    }
}

// export default Page;
export default props => (
    <StaticQuery query={graphql`
        query pmediaCollection {
            allImageSharp {
                nodes {
                    fluid(maxWidth: 1920, srcSetBreakpoints: [600,900,1920]) {
                        ...GatsbyImageSharpFluid,
                        originalName,
                        presentationWidth
                    },
                    thumbnail:fixed(width: 100, height: 110) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `} 
    render={data => <Page mediaCollection={data.allImageSharp.nodes} {...props}/>}/>
)