import React, { Component } from 'react';
import PropTypes from "prop-types";

import '../../styles/multicolumn.scss';
// import { ThemeContext } from '../../context/ThemeProvider';
// import { stripHtml } from '../../utilities/helpers';

const variants = [
    {
        id: 1,
        name: "1 column, full width, vertically and horizontally centered text with overlay",
        flex: "flex mulcol-overlay-container",
        width: "",
        content: "mulcol-text-content"
    },
    {
        id: 2,
        name: "1 column, padded both sides, bottom aligned text",
        flex: "flex",
        width: "layout-pad-side",
        content: ""
    },
    {
        id: 3,
        name: "2 columns, full width, vertically and horizontally centered text with overlay",
        flex: "flex-2-cols mulcol-overlay-container",
        width: "",
        content: "mulcol-text-content"
    },
    {
        id: 4,
        name: "2 columns, padded both sides, bottom aligned text",
        flex: "flex-2-cols",
        width: "layout-pad-side",
        content: ""
    },
    {
        id: 5,
        name: "3 columns, full width, vertically and horizontally centered text with overlay",
        flex: "flex-3-cols mulcol-overlay-container",
        width: "",
        content: "mulcol-text-content"
    },
    {
        id: 6,
        name: "3 columns, padded both sides, bottom aligned text",
        flex: "flex-3-cols",
        width: "layout-pad-side",
        content: ""
    }
];

export class MultiColumn extends Component {
    componentDidMount() {
        const blurredImageDiv = document.querySelectorAll(".blurred-img:not(.loaded)");
        
        blurredImageDiv.forEach((item) => {
            const img = item.querySelector("img");
            if (!img) return;
            function loaded() {
                item.classList.add("loaded")
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        });
    }
    
    buildMultiColumn(variantSettings, columns) {
        const { mediaQueries } = this.props;

        return (
            <div className={`layout-row ${variantSettings.width}`}>
                {
                    columns.map((column, index) => {
                        const imgPath = column.media.url.split("/");
                        const imageData = mediaQueries.find(x => x.fluid.originalName === imgPath[imgPath.length - 1]);

                        if (variantSettings.id === 1 || variantSettings.id === 3 || variantSettings.id === 5) {
                            return (
                                <div className={`mulcol--item-content ${variantSettings.flex}`}>
                                    <div className='multicolumn-bg blurred-img' style={{
                                        backgroundImage: `url(${imageData.fluid.base64})`,
                                        backgroundPosition: 'bottom',
                                        position: 'absolute'
                                    }}>
                                        <img loading='lazy' src={imageData.fluid.src} alt={column.title} />
                                        <div className={`text-center text-content ${variantSettings.content}`} dangerouslySetInnerHTML={{ __html: column.title }}></div>
                                    </div>
                                </div>
                            )
                        }
                        else if (variantSettings.id === 2 || variantSettings.id === 4 || variantSettings.id === 6) {
                            return (
                                <div className={`mulcol--item-content ${variantSettings.flex}`}>
                                    <div className='multicolumn-bg blurred-img' style={{
                                        backgroundImage: `url(${imageData.fluid.base64})`,
                                        backgroundPosition: 'bottom'
                                    }}>
                                        <img loading='lazy' src={imageData.fluid.src} alt={column.title} />
                                    </div>
                                    <div className={`text-center text-content ${variantSettings.content}`} dangerouslySetInnerHTML={{ __html: column.title }}></div>
                                </div>
                            )
                        }

                        return '';
                    })
                }
            </div>
        )
    }

    render() {
        const { title, variant, columns, backgroundColor, sectionIdentifier } = this.props;
        let variantSettings = variants.filter(x => x.name === variant)[0];
        const customSettings = {};
        const hashSettings = {};

        if (backgroundColor) {
            customSettings.style = {
                backgroundColor: backgroundColor
            };
        }

        if (sectionIdentifier) {
            hashSettings.id = sectionIdentifier;
        }

        return (
            <div {...customSettings} style={{position: 'relative'}}>
                <div {...hashSettings} className="element-hash-view"></div>
                <div className="layout-column mult-column">
                    <div className="flex text-center mult-column__title" dangerouslySetInnerHTML={{ __html: title }}>
                    </div>
                    {this.buildMultiColumn(variantSettings, columns)}
                </div>
            </div>
        )
    }
}

MultiColumn.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    backgroundColor:  PropTypes.string,
    menuBackgroundColor:  PropTypes.string,
    sectionIdentifier: PropTypes.string
}

export default MultiColumn;
