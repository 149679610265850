import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { ThemeContext } from '../../context/ThemeProvider';

import '../../styles/listingmulticolumn.scss';
import { Link } from 'gatsby';

export class ListingMultiColumn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noOfListingDisplayed: props.listingToShowPerColumn,
            totalListingCount: props.listings.length,
            toggleText: 'Show more'
        }
    }

    componentDidMount() {
        // Apply text overlay if image is fully visible or image visibility is > 95%
        window.addEventListener('scroll', this.onScroll);
        this.loadBlurredImages();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        let elements = document.getElementsByClassName('lismulcol-background-overlay');
        for (let index = 0; index < elements.length; index++) {
            if (elements[index].getBoundingClientRect().top < 170) {
                elements[index].style.opacity = 1;
            }
        }
    }

    getFlexColumns(listingToShowPerColumn, index, noOfListingDisplayed) {
        let flex = '';

        if (noOfListingDisplayed % listingToShowPerColumn === 0) {
            switch (listingToShowPerColumn) {
                case 1:
                    flex = 'flex';
                    break;
                case 2:
                    flex = 'flex-2-cols';
                    break;
                case 3:
                    flex = 'flex-grow-3-cols';
                    break;
                default:
                    flex = 'flex';
                    break;
            }
        }
        else {
            if (noOfListingDisplayed === index) {
                var overflow = noOfListingDisplayed % listingToShowPerColumn;
                switch (overflow) {
                    case 1:
                        flex = 'flex-grow-1-col';
                        break;
                    case 2:
                        flex = 'flex-2-cols';
                        break;
                    default:
                        flex = 'flex';
                        break;
                }
            }
            else {
                switch (listingToShowPerColumn) {
                    case 1:
                        flex = 'flex';
                        break;
                    case 2:
                        flex = 'flex-2-cols';
                        break;
                    case 3:
                        flex = 'flex-grow-3-cols';
                        break;
                    default:
                        flex = 'flex';
                        break;
                }
            }
        }

        return flex;
    }

    loadBlurredImages = () => {
        const blurredImageDiv = document.querySelectorAll(".listing-mult-column__container .blurred-img:not(.loaded)");
        if(blurredImageDiv.length <= 0) return;
        
        blurredImageDiv.forEach((item) => {
            const img = item.querySelector("img");
            if (!img) return;
            function loaded() {
                item.classList.add("loaded")
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        });
    }

    onShowMoreListing = () => {
        let { noOfListingDisplayed, totalListingCount, toggleText } = this.state;
        const { listingToShowPerColumn } = this.props;
        let isMax = (noOfListingDisplayed + noOfListingDisplayed) >= totalListingCount;
        let collapsed = false;

        if (isMax) {
            if (toggleText === 'Show less') {
                noOfListingDisplayed = listingToShowPerColumn;
                toggleText = 'Show more';
                collapsed = true;
            }
            else {
                toggleText = 'Show less';
                noOfListingDisplayed = totalListingCount;
            }
        }
        else {
            toggleText = 'Show more';
            noOfListingDisplayed += noOfListingDisplayed;
        }

        this.setState({ noOfListingDisplayed, toggleText }, () => {
            this.loadBlurredImages();

            if (isMax && collapsed) {
                var element = document.querySelector('.listing-mult-column__container');
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        });
    }

    render() {
        const { listings, listingToShowPerColumn, buttonText, backgroundColor, sectionIdentifier, mediaQueries } = this.props;
        const { noOfListingDisplayed, toggleText } = this.state;
        const customSettings = {};
        const theme = this.context;

        if (backgroundColor) {
            customSettings.style = {
                backgroundColor: backgroundColor
            };
        }

        if (sectionIdentifier) {
            customSettings.id = sectionIdentifier;
        }

        let displayedListings = noOfListingDisplayed !== listings.length ? listings.slice(0, noOfListingDisplayed) : listings;
        let animationClass = displayedListings.length > 3 ? 'js-animate-slide-in-down' : '';

        return (
            <div {...customSettings} className="listing-mult-column__container">
                <div className="layout-row overflow-hidden">
                    {
                        displayedListings.map((listing, index) => {
                            if (listing.pictures.length === 0) return '';
                            
                            let imgPath = listing.pictures[0].url.split("/");
                            const imageData = mediaQueries.find(x => x.fluid.originalName === imgPath[imgPath.length - 1]);

                            return (
                                <div className={`layout-column layout--centered lismulcol-inner-container ${animationClass} ${this.getFlexColumns(listingToShowPerColumn, index + 1, noOfListingDisplayed)}`}>
                                    <div className="blurred-img lismulcol-inner-container--background" style={{
                                        backgroundImage: `url(${imageData.fluid.base64})`,
                                        backgroundPosition: 'bottom',
                                        position: 'absolute'
                                    }}>
                                        <img loading='lazy' src={imageData.fluid.src} alt={listing.title} />
                                    </div>
                                    <Link to={listing.url} 
                                        state={{ listingId: listing.id }}
                                    >
                                        <div className="lismulcol-background-overlay layout-column layout--centered">
                                            <div className="lismulcol--content text-center text-white">{`${listing.address[0].city}, ${listing.address[0].country}`}</div>
                                            <div className="lismulcol--content--title text-center text-white">{listing.title}</div>
                                            <div className="lismulcol--content">
                                                <button className={`btn ${theme.buttonLight}`}>{buttonText}</button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="layout-column layout--centered lismulcol-show-label">
                    <span className={`clickable ${toggleText === 'Show more'? 'more' : 'less'}`} onClick={this.onShowMoreListing}>{toggleText}</span>
                </div>
            </div>
        )
    }
}

ListingMultiColumn.contextType = ThemeContext;

ListingMultiColumn.propTypes = {
    listings: PropTypes.arrayOf(PropTypes.object),
    listingToShowPerColumn: PropTypes.number,
    backgroundColor: PropTypes.string,
    sectionIdentifier: PropTypes.string
}

export default ListingMultiColumn;
