import React from 'react';
import PropTypes from "prop-types";

import BaseForm from '../BaseForm';
import { ThemeContext } from '../../context/ThemeProvider';
import formService from '../../services/FormService';
import Axios from 'axios';

import loader from '../../images/button-loader.svg';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Form extends BaseForm {
    constructor(props) {
        super(props);

        const { formContent } = props;
        const { formFields } = formContent;

        let formData = this.buildData(formFields);
        let dropdownValues = this.buildDropdownValues(formFields);

        this.state = {
            data: formData,
            errors: {},
            dropdownValues: dropdownValues,
            submitting: false,
            errorMessage: '',
            successMessage: ''
        }

        this.schema = this.buildSchema(formFields);
    }

    componentDidMount() {
        this.loadRecaptcha();
    }

    componentWillUnmount() {
        source.cancel();

        document.getElementById('script-grecaptcha').remove();
        document.getElementById('init-recaptcha').remove();
        var badge = document.getElementsByClassName('grecaptcha-badge');
        while(badge.length > 0) {
            badge[0].parentNode.remove(badge[0]);
        }
    }

    doSubmit = async () => {
        const { data } = this.state;
        const { formContent } = this.props;
        let errors = this.state.errors;
        let hasErrors = false;

        formContent.formFields.forEach(field => {
            const { type, required, name } = field;

            if (type === "Checkbox") {
                if (required) {
                    hasErrors = !data[name];
                    if (hasErrors) {
                        errors[name] = 'required';
                    }
                }
            }
        });
        
        if (hasErrors) {
            this.setState({ errors, submitting: false, successMessage: '' });
            // e.currentTarget.querySelector('button[type="submit"]').removeAttribute('disabled');
            return;
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LfSCGAnAAAAAJ6vHAb6XKCu0Hm8zgMKb1DAZkD_', { action: 'social' }) // TODO: Add recaptcha site key
                .then(t => {
                    source.cancel();
                    source = CancelToken.source();
                    
                    let formData = new FormData();
                    data.token = t;
                    formData.set("formId", formContent.formId);
                    formData.set("integrationType", formContent.it);
                    formData.set("rawContent", JSON.stringify(data));
            
                    formService.submit(formData, source.token).then(response => {
                        if (response.success) {
                            const { formFields } = this.props.formContent;
                            const formData = this.buildData(formFields);
                            this.setState({ data: formData, successMessage: 'We have successfully submitted your inquiry.', submitting: false });
                        }
                        else {
                            this.setState({ errorMessage: response.errorMessage, submitting: false, successMessage: '' });
                        }
                    });
                })
        });
    }

    loadRecaptcha() {
        const script = document.createElement("script");
        script.id = "script-grecaptcha";
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfSCGAnAAAAAJ6vHAb6XKCu0Hm8zgMKb1DAZkD_"; // TODO: Add recaptcha site key
        document.body.appendChild(script);
    }

    render() {
        const { formContent, backgroundColor, sectionIdentifier } = this.props;
        const { title, description, formFields, submitButtonText } = formContent;
        const customSettings = {};
        const theme = this.context;
        const { errors, successMessage, submitting } = this.state;
        let buttonStyle = {};

        if (backgroundColor) {
            customSettings.style = {
                backgroundColor: backgroundColor
            };
        }

        if (sectionIdentifier) {
            customSettings.id = sectionIdentifier;
        }

        if (submitting) {
            buttonStyle = {
                backgroundImage: `url(${loader})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }
        }

        return (
            <div {...customSettings}>
                <div className="layout-column layout-pad-top layout-pad-bottom form-section">
                    <div className="form-section__title" dangerouslySetInnerHTML={{__html: title}}></div>
                    <div className="form-section__text-excerpt" dangerouslySetInnerHTML={{__html: description}}></div>
                    <form className="form" onSubmit={this.handleSubmit}>
                        <div className={`notification ${successMessage ? 'notification--success' : ''} ${Object.keys(errors).length > 0 ? 'notification--error' : ''}`}>
                            { Object.keys(errors).length > 0 ? 'Please fill in all required fields and make sure that you have agreed to the Privacy Policy' : successMessage }
                        </div>
                        {
                            formFields.length > 0 && 
                            <div className="form-inline">
                                <div>{this.generateField(formFields[0], this.state.data, !!errors[formFields[0].name])}</div>
                                <div>{this.generateField(formFields[1], this.state.data, !!errors[formFields[1].name])}</div>
                            </div>
                        }
                        {
                            formFields.slice(2, formFields.length).map((field, index) => (
                                this.generateField(field, this.state.data, !!errors[field.name])
                            ))
                        }
                        <div>
                            <div
                                id="init-recaptcha"
                                className="g-recaptcha"
                                data-sitekey="6LfSCGAnAAAAAJ6vHAb6XKCu0Hm8zgMKb1DAZkD_" // TODO: Add recaptcha site key
                                data-size="invisible"
                            />
                        </div>
                        <div>
                            <button type="submit" style={buttonStyle} className={`btn ${theme.buttonPrimary}`} disabled={submitting}>
                                {submitting ? '' : submitButtonText}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

Form.contextType = ThemeContext;

Form.propTypes = {
    formContent: PropTypes.object,
    backgroundColor: PropTypes.string,
    sectionIdentifier: PropTypes.string
}

export default Form;
