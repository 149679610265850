import React from 'react';
import { Component } from 'react'
// import Joi from '@hapi/joi';
import { string, number, boolean, object } from 'yup';
import { stripHtml } from '../utilities/helpers';

export class BaseForm extends Component {
    state = {
        data: {},
        errors: {}
    }
    
    // buildSchema(formFields) {
    //     let data = formFields.reduce((obj, item) => {
    //         let dataType;

    //         switch (item.type) {
    //             case 'Text':
    //             case 'Textarea':
    //             case 'Dropdown':
    //             case 'Telephone Number':
    //                 dataType = Joi.string();
    //                 break;
    //             case 'Email':
    //                 dataType = Joi.string().email({ minDomainSegments: 2, tlds: false });
    //                 break;
    //             case 'Checkbox':
    //                 dataType = Joi.boolean();
    //                 break;
    //             case 'Number':
    //                 dataType = Joi.number();
    //                 break;
    //             default:
    //                 break;
    //         }

    //         if (item.required) {
    //             dataType = dataType.required();
    //         }
    //         else {
    //             dataType = dataType.allow(null).allow('');
    //         }

    //         return {
    //             ...obj,
    //             [item.name]: dataType
    //         }
    //     }, {});

    //     return Joi.object(data);
    // }

    buildSchema(formFields) {
        let data = formFields.reduce((obj, item) => {
            let dataType;

            switch (item.type) {
                case 'Text':
                case 'Textarea':
                case 'Dropdown':
                case 'Telephone Number':
                    dataType = string();
                    break;
                case 'Email':
                    dataType = string().email();
                    break;
                case 'Checkbox':
                    dataType = boolean();
                    break;
                case 'Number':
                    dataType = number();
                    break;
                default:
                    break;
            }

            if (item.required) {
                dataType = dataType.required();
            }
            // else {
            //     dataType = dataType.allow(null).allow('');
            // }

            return {
                ...obj,
                [item.name]: dataType
            }
        }, {});

        return object().shape(data);

    }

    buildData(formFields) {
        return formFields.reduce((obj, item) => {
            let defaultValue = '';

            switch (item.type) {
                case 'Text':
                case 'Textarea':
                case 'Email':
                case 'Dropdown':
                case 'Url':
                case 'Telephone Number':
                    defaultValue = '';
                    break;
                case 'Checkbox':
                    defaultValue = false;
                    break;
                case 'Number':
                    defaultValue = 0;
                    break;
                default:
                    break;
            }

            return {
              ...obj,
              [item.name]: defaultValue
            };
        }, {});
    }

    buildDropdownValues(formFields) {
        let dropdownFields = formFields.filter(x => x.type === 'Dropdown');

        if (dropdownFields.length < 0)
            return null;

        return dropdownFields.reduce((obj, item) => {
            return {
                ...obj,
                [item.name]: item.dropdownValues.split(",")
            }
        }, {});
    }

    validate = async () => {
        await this.schema.validate(this.state.data, { 
            abortEarly: false 
        }).then(() => {
            this.setState({ errors: {}, submitting: true });
            this.doSubmit();
        }).catch(error => {
            const errors = {};
            
            for (let item of error.inner)
                errors[item.path] = item.message;
            
            this.setState({ errors, successMessage: '' });
        });
    }

    handleChange = (e) => {
        const { name, value, type, checked } = e.currentTarget;
        const { data } = this.state;
        
        if (name === 'mobileNumber') {
            data[name] = value.replace(/\D/g,'');
        }
        else if (type === "checkbox") {
            data[name] = checked;
        }
        else {
            data[name] = value;
        }

        this.setState({ data });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        this.validate();
    }

    generateField(field, data, hasError) {
        let { label, name, type, dropdownValues, required } = field;

        let formField = null;

        switch (type) {
            case 'Text':
            case 'Email':
                formField = <div className="form-field">
                    <input type={type} name={name} className={`${hasError ? "has-error":""}`} autoComplete="off" onChange={this.handleChange} value={data[name]} onBlur={e => {
                        const { value } = e.currentTarget;
                        if (value) {
                            e.currentTarget.classList.add('has-value');
                        }
                        else {
                            e.currentTarget.classList.remove('has-value');
                        }
                    }} />
                    <label className="label-name">
                        <span className="content-name">{`${stripHtml(label)}${required ? '*' : ''}`}</span>
                    </label>
                </div>
                break;
            case 'Dropdown':
                formField = <select name={name} onChange={this.handleChange} value={data[name]}>
                    <option></option>
                    {
                        dropdownValues.split(',').map((val, index) => <option>{val}</option>)
                    }
                </select>
                break;
            case 'Checkbox':
                formField = <div style={{ paddingLeft: 10 }} className="form-field--checkbox">
                        <input type={type} id={name} name={name} onChange={this.handleChange} checked={data[name]} />
                        <label htmlFor={name} className={`checkbox-label ${hasError ? "has-error":""}`}><span dangerouslySetInnerHTML={{__html: label}}></span></label>
                    </div>
                break;
            case 'Textarea':
                formField = 
                <div className="form-field">
                    <div className="textarea">
                        <textarea type={type} className={`${hasError ? "has-error":""}`} name={name} onChange={this.handleChange} rows={5} value={data[name]} onBlur={e => {
                            const { value } = e.currentTarget;
                            if (value) {
                                e.currentTarget.classList.add('has-value');
                            }
                            else {
                                e.currentTarget.classList.remove('has-value');
                            }
                        }} />
                        <label className="label-name">
                            <span className="content-name">{`${stripHtml(label)}${required ? '*' : ''}`}</span>
                        </label>
                    </div>
                </div>
                break;
            default:
                break;
        }

        return formField;
    }
}

export default BaseForm
