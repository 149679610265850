import httpClient from '../utilities/HttpClient';

const API = {
    submit: '/api/form/submit'
}

class FormService {
    async submit(data, token) {
        return httpClient.Post(API.submit, data, token, true);
    }
}

let formService = new FormService();
export default formService;